<button
  mat-button
  [matMenuTriggerFor]="colorSchemeMenu"
  class="theme-toggle"
  title="Toggle theme"
  aria-label="Toggle theme"
  matTooltip="{{ colorSchemeText() }} theme"
>
  <fa-icon [icon]="paletteIcon"></fa-icon>
</button>
<mat-menu #colorSchemeMenu="matMenu">
  @for (variant of variants; track $index) {
    <button mat-menu-item (click)="setColorScheme(variant)" [ngClass]="{ selected: variant == colorScheme() }">
      {{ capitalize(variant) }}
    </button>
  }
  <hr class="my-0" />
  <button mat-menu-item class="pl-1">
    <mat-checkbox [checked]="centerLayoutMode" (click)="updateCenterLayout()">Center layout</mat-checkbox>
  </button>
</mat-menu>

@switch (colorScheme()) {
  @case ('default') {}
  @case ('tan') {
    <link href="/assets/themes/tan.css" rel="stylesheet" />
  }
  @case ('green') {
    <link href="/assets/themes/green.css" rel="stylesheet" />
  }
  @case ('gold') {
    <link href="/assets/themes/gold.css" rel="stylesheet" />
  }
  @case ('red') {
  	<link href="/assets/themes/red.css" rel="stylesheet" />
  }  
  @case ('pink') {
  	<link href="/assets/themes/pink.css" rel="stylesheet" />
  }
}

@if (centerLayoutMode) {
  <link href="/assets/themes/center-column.css" rel="stylesheet" />
}
