<mat-drawer-container>
  <mat-drawer style="position: fixed; border-radius: 0" class="side-menu" [mode]="mobile ? 'over' : 'side'"
    [opened]="menuVisible || !mobile" (closed)="hideMenu()">
    <a [href]="'/'" class="block" (mousedown)="hideMenu()">
      <img [src]="logo" class="block mx-auto mt-3" alt="instance logo" width="200"
        style="aspect-ratio: 3 / 1; object-fit: contain" />
    </a>
    <hr />
    <nav aria-label="Navigation menu">
      <mat-nav-list>
        @for (link of menuItems; track $index) {
        @if (link.divider) {
        <hr />
        } @else {
        <app-menu-item [item]="link"></app-menu-item>
        }
        }
      </mat-nav-list>
    </nav>
    <div class="drawer-footer">
      <app-theme-switcher></app-theme-switcher>
      <app-color-scheme-switcher></app-color-scheme-switcher>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <button style="position: fixed; z-index: 500; top: 1em; left: 0.5em" mat-flat-button color="primary"
      [hidden]="!mobile" type="button" class="show-sidebar-menu-button" (mousedown)="showMenu()"
      [matBadge]="notifications + adminNotifications + usersAwaitingApproval + followsAwaitingApproval + awaitingAsks"
      [matBadgeHidden]="
        notifications + adminNotifications + usersAwaitingApproval + followsAwaitingApproval + awaitingAsks === 0
      " matBadgePosition="below">
      <fa-icon [icon]="hamburguerIcon"></fa-icon>
    </button>
    <button style="position: fixed; z-index: 500; bottom: 1em; right: 0.5em" mat-flat-button color="primary"
      [hidden]="!mobile || !jwtService.tokenValid() || currentRoute === '/editor'" type="button"
      class="show-sidebar-menu-button" (mousedown)="this.hideMenu(); this.openEditor()">
      <fa-icon [icon]="pencilIcon"></fa-icon>
    </button>
    <div class="wafrn-content">
      <div *ngIf="maintenanceMode" [innerHtml]="maintenanceMessage"></div>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>